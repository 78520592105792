html,
body,
#root {
  min-height: 100% !important;
  height: 100%;
}

.app {
  height: 100%;
}

h3 {
  color: #16141a;
}

:root {
  --light-gray: #dadce5;
  --gray: #4f4f4f;
  --green: #44b600;
  --orange: #ff7439;
  --revoked-color: #ff2d55;
  --paused-color: #ffc95b;
  --today-color: #736e7d;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
}
