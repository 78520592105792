.mkc.logo {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-image: url('../../images/logo.svg');
  background-size: contain;
}

@media (max-width: 768px) {
  .mkc.logo {
    width: 32px;
    height: 32px;
  }
}
